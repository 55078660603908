._boss .load-mask {

	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	background: rgba(255, 255, 255, 0.5);
	z-index: 5;

	align-items: center;
	justify-content: center;
	opacity: 1;
	overflow: hidden;

	transition: opacity 300ms ease-in-out 5ms, height 300ms, width 300ms, left 01ms;
	.loading-text{
		.load__dot{
			animation: 1s blink infinite;
		}
		.load__dot:nth-child(2){
			animation-delay: 250ms;
		}
		.load__dot:nth-child(3){
			animation-delay: 250ms;
		}
	}
	
	&:before {
		text-align: center;
		content: '\f3f4';
		font-family: 'FontAwesome';
		font-size: 30px;
		color: #666;

		-webkit-animation: rotating 1s linear infinite;
		-moz-animation: rotating 1s linear infinite;
		-ms-animation: rotating 1s linear infinite;
		-o-animation: rotating 1s linear infinite;
		animation: rotating 1s linear infinite;
		margin-bottom:5px;
	}
	&:not(.show) {
		max-height: 0;
		max-width: 0;
		opacity: 0;
		top: auto;
		right: auto;
		bottom: auto;
		left: -100000px;
		transition: opacity 300ms ease-in-out 5ms, height 1ms 301ms, width 1ms 301ms, left 1ms 301ms;
	}
}


@keyframes blink {
	50%{
		color: transparent
	}
}