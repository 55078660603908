#_boss {
	-webkit-font-smoothing: antialiased;

	--sidebar-reveal-timing-ms: 300;
}

body {
	--base-z-index: 10;
	--modal-backdrop-z-index: calc(var(--base-z-index) + 10);
	--modal-z-index: calc(var(--base-z-index) + 20);
	--sidebar-z-index: calc(var(--base-z-index) + 30);

	font-family: 'Comfortaa', 'Quicksand', sans-serif;

	[role='dialog'][data-dialog-type='Sidebar'].show {
		z-index: var(--sidebar-z-index);
	}
	// naturally have sidebars on top of modals and backdrop underneath topmost
	&:has([role='dialog']) {
		--modal-backdrop-z-index: calc(var(--modal-z-index) - 1);

		&:has([role='dialog'][data-dialog-type='Sidebar']) {
			--modal-backdrop-z-index: calc(var(--sidebar-z-index) - 1);
		}
	}

	// allow qr code display to float above everything
	[role='dialog']:has([data-component="ScannableCodeDisplay"]) {
		z-index: calc(var(--sidebar-z-index) + 1);

		// TODO remove in PDP CTA branch once this is merged in
		.carousel-indicators {
			display: none;
		}
	}
}

:is(.offcanvas-backdrop, .modal-backdrop.show) {
	z-index: var(--modal-backdrop-z-index);
}


:is(#_boss, ._boss[role = "dialog"], .tooltip[role = "tooltip"], .swal2-container, .medium-zoom-overlay, .medium-zoom-image) {
	--boss-blue: #09f;

	.iconable {
		&::before, &::after {
			font-family: 'Font Awesome 6 Pro';
		}
	}
}

:is(._boss[role = "dialog"], .tooltip[role = "tooltip"], .swal2-container, .medium-zoom-image--opened) {
	z-index: var(--modal-z-index);

	&.tooltip[role = "tooltip"] {
		background: transparent;
	}
}

// for larger screens, keep bookmarks open side-by-side with PDP for easier switching
@media (width > 1200px) {
	// if PDP open with sidebar, layout side-by-side without backdrop
	body:has([role="dialog"].product-detail-modal):has([role='dialog'][data-dialog-type='Sidebar']) {
		--modal-backdrop-z-index: 0;
		--sidebar-width: var(--bs-offcanvas-width, 400px);

		[role="dialog"].product-detail-modal {
			--width: calc(100% - var(--sidebar-width));

			width: var(--width);

			.modal-content {
				width: var(--width);
			}
		}
	}
}

// make sure tooltip doesn't overlap with any dropdown menus
#root:has(.dropdown-menu.show) ~ .tooltip[role = "tooltip"] {
	z-index: -1;
}

._boss {
	dialog {
		// some sites have init styles setting <dialog> display to block, that will show it always; override
		&:not([open]) {
			display: none;
		}
	}
	
	.dropdown-menu.show {
		z-index: calc(var(--modal-z-index) + 100);
	}
}

// override Dialog.fire() styling
.swal2-container > .swal2-popup {
	padding-top: 1em;

	&.swal2-show {
		animation: dialog-show 250ms ease-in-out forwards;
	}

	.swal2-icon {
		height: 2em;
		width: 2em;
		margin-top: 1em;
		animation: unset;

		.swal2-icon-content {
			font-size: 1.4em;
			animation: unset;
		}
	}

	.swal2-title {
		padding-top: 0;
	}

	@keyframes dialog-show {
		from {
			scale: 0.9;
			opacity: 0;
		}
		to {
			scale: 1;
			opacity: 1;
		}
	}
}