// Override default variables before the import
// $primary: #f90;
// $secondary: #09f;
// $body-color: #333;
// $body-font-family: "Open Sans", sans-serif;

$dropdown-link-active-bg: #666;

// Import Bootstrap and its default variables
@import 'bootstrap-prefixed';

body {
	padding: 0;
	margin: 0;
}
._boss {
	.error {
		white-space: pre-line;
	}
	&.tooltip-success {
		> .tooltip-inner {
			background-color: var(--bs-success);
		}
		&.bs-tooltip-top {
			.tooltip-arrow::before {
				border-top-color: var(--bs-success);
			}
		}
		&.bs-tooltip-bottom {
			.tooltip-arrow::before {
				border-bottom-color: var(--bs-success);
			}
		}
		&.bs-tooltip-start {
			.tooltip-arrow::before {
				border-left-color: var(--bs-success);
			}
		}
		&.bs-tooltip-end {
			.tooltip-arrow::before {
				border-right-color: var(--bs-success);
			}
		}
	}

	.dropdown-menu a.dropdown-item {
		&:before {
			display: inline-block;
			font-family: 'Font Awesome 6 Pro';
			padding-right: 10px;
		}

		&.edit::before {
			content: '\f304';
		}
		&.delete::before {
			content: '\f2ed';
		}
		&.po-number::before {
			content: '\23';
		}
		&.close::before {
			content: '\f057';
		}
	}

	.user-brand-logo-container {
		.user-nav > a.dropdown-toggle {
			text-decoration: none;

			&:before {
				font-weight: lighter;
				font-size: 20px;
				font-family: 'Font Awesome 6 Pro';
			}
		}
	}
	header {
		button.chat-nav,
		button.proposal-nav,
		button.bookmark-nav,
		.user-nav > a.dropdown-toggle {
			text-decoration: none;

			&:before {
				font-weight: light;
				font-size: 20px;
				font-family: 'Font Awesome 6 Pro';
				font-weight: lighter;
			}
		}
	}
	button.btn {
		position: relative;
		font-size: 14px;

		&.btn-primary {
			min-width: 130px;
		}
		&:before {
			font-family: 'Font Awesome 6 Pro';
			padding-right: 8px;
		}
		&:empty:before {
			padding-right: 0;
		}
		&:after {
			content: ' ';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 0;
			border-radius: 5px;
			overflow: hidden;

			background: rgba(255, 255, 255, 0.1);
			background-image: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
			background-position: 50% 50%;
			background-repeat: no-repeat;
			background-size: 35% 20%;
			opacity: 0;

			transition: background 300ms ease-out, opacity 300ms ease-out;
		}
		&:hover:after {
			background-size: 100% 100%;
			opacity: 1;
		}
	}
	button.btn.dropdown-toggle:after {
		position: inherit;
		opacity: 1;
	}
	p.company {
		white-space: nowrap;
	}
	.download {
		background: transparent;
		border: 0;
		outline: 0;
		text-decoration: none;
		&:before {
			content: '\f33d';
			font-family: 'Font Awesome 6 Pro';
		}
	}
	// .dropdown-item:active,
	// button.btn:not(.btn-link),
	// button.btn:not(.btn-link):focus,
	// button.btn:not(.btn-link):hover {
	// 	color: var(--button-primary-color);
	// 	background-color: var(--button-primary-bg);
	// 	border-color: inherit;
	// }
	// button.btn.btn-secondary,
	// button.btn.btn-secondary:focus,
	// button.btn.btn-secondary:hover {
	// 	background-color: var(--button-primary-color);
	// 	color: var(--button-primary-bg);
	// 	border: 1px var(--button-primary-bg) solid;
	// }
	#load-mask {
		position: fixed;
		z-index: -1;
		opacity: 0;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(255, 255, 255, 0.5);
		transition: opacity 500ms ease-in-out;
		> #load-mask-graphic {
			width: 200px;
			height: 200px;
			display: inline-block;
			overflow: hidden;
			transform: scale(0.25);
			transition: opacity 500ms ease-in-out 250ms;
			opacity: 0;

			span div {
				position: absolute;
				border-width: 5px;
				border-style: solid;
				opacity: 1;
				border-radius: 50%;
			}
			> span {
				width: 100%;
				height: 100%;
				position: relative;
				transform: translateZ(0) scale(1);
				backface-visibility: hidden;
				transform-origin: 0 0; /* see note above */

				div {
					box-sizing: content-box;
				}
				div:nth-child(1) {
					border-color: #bbbbbb;
					animation-delay: 0s;
				}
				div:nth-child(2) {
					border-color: #999999;
					animation-delay: -0.5s;
				}
			}
		}
	}
	.is-loading > #load-mask,
	#load-mask.show {
		z-index: 100;
		opacity: 1;
		display:flex;
		flex-direction: column;
		> #load-mask-graphic {

			opacity: 1;
			span div {
				animation: load-mask-animation 1.5s cubic-bezier(0, 0.2, 0.8, 1) infinite;
			}
		}
	}

	.fs-large { font-size: large;}
	.fs-larger { font-size: larger;}
	.fs-medium { font-size: medium;}
	.fs-smaller { font-size: smaller;}
	.fs-small { font-size: small;}
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
	from {
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes rotating {
	from {
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes fade-in-out {
	0%,
	100% {
		opacity: 0.5;
	}
	50% {
		opacity: 1;
	}
}
@keyframes load-mask-animation {
	0% {
		top: 96px;
		left: 96px;
		width: 0;
		height: 0;
		opacity: 1;
	}
	100% {
		top: 18px;
		left: 18px;
		width: 156px;
		height: 156px;
		opacity: 0;
	}
}

._boss .swal2-container {
	h2.swal2-title {
		font-size: 24px;
	}
	button.swal2-styled {
		padding: 8px 20px;
		margin: 8px;
		font-size: 14px;
	}
}
._boss .swal2-styled {
	&.swal2-confirm {
		&:hover {
			opacity: 0.8;
		}
		background-color: var(--button-primary-bg) !important;
		color: var(--button-primary-color) !important;
		border-color: var(--button-primary-color) !important;
	}
	&.swal2-deny,
	&.swal2-cancel {
		background-color: var(--button-secondary-bg) !important;
		color: var(--button-secondary-color) !important;
		border-color: var(--button-secondary-color) !important;
		// color: var(--button-primary) !important;
		// border-color: var(--button-primary) !important;
		// background-color: var(--button-primary), inherit !important;
		// border: 1px solid var(--button-primary) !important;
	}
}
._boss.dialog {
	background-color: transparent;
	header .MuiAppBar-colmrPrimary {
		background-color: var(--highlight-bg);
		color: var(--highlight-fg);
	}
	.MuiBackdrop-root {
		background-color: rgba(255, 255, 255, 0.5);
	}
	@media (min-width: 600px) {
		.MuiToolbar-root {
			min-height: 32px;
		}
	}
}

/*
    TODO .. this causes an unruly shift of the whole page
     and I don't know why you'd want that
     so I'm removing it until someone figures that out
     
     -Jake
 */
//body:not(.swal2-shown), body:not(.modal-open) {
//	padding-right: 0px !important;
//}
//body.swal2-shown, body.modal-open {
//	padding-right: 17px !important;
//}

.modal-dialog .modal-content {
	.modal-body {
		&.boss-invite-modal {
			.boss-invite-display-text {
				font-family:'Courier New', Courier, monospace;
				padding:1rem;
				background-color: rgb(241 245 249 / 1);
				border-width:1px;
				border-radius: 0.375rem;
				display:flex;
				justify-content: center;
				cursor:pointer;
				position:relative;

				.copy-icon{
					top: 0.5rem;
					right: 0.5rem;
					position:absolute;
				}
				.copy-text{
					white-space:pre-line;
					.url{
						color: rgb(37 99 235 / 1);
					}
				}
			}

			.boss-invite-modal-inputs{
				margin-top: 0.5rem;
				.close-btn{
					float:left
				}
				.copy-btn{
					float:right;
				}
			}
		}

		&.boss-change-location-modal{
			.boss-change-location-header{
				opacity: 0.5;
				font-size: 0.7em;
				margin-left: 3px;
				margin-bottom: 10px;
			}

			.boss-change-location-modal-inputs{
				margin-top: 0.5rem;
				.close-btn{
					float:left
				}
				.change-btn{
					float:right;
				}
			}
		}
	}
	.modal-body .edit-organization-form{

		.loadingMask{
			display:flex;
			justify-content: center;
	
			-webkit-animation: rotating 1s linear infinite;
			-moz-animation: rotating 1s linear infinite;
			-ms-animation: rotating 1s linear infinite;
			-o-animation: rotating 1s linear infinite;
			animation: rotating 1s linear infinite;
			&:before {
				text-align: center;
				content: '\f3f4';
				font-family: 'FontAwesome';
				font-size: 30px;
				color: rgb(96 165 250);
	
	
			}
		}
	
		input.boss-color-input[type="color"]{
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			border: none;
		}
	}
}